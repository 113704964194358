import React, { useState } from "react";
import Topbar from './components/Topbar/Topbar.js';
import TopBarMobile from './components/TopBarMobile/TopBarMobile.js'
import Home from './components/Home/Home.js';
import { BrowserRouter as Router, Route } from "react-router-dom";
import CareerOffer from './components/CareerOffer/CareerOffer.js';
import "./App.css";
import{useMediaQuery} from 'react-responsive'

function App() {
  const isMobile = useMediaQuery({query : '(max-width:1023px'});

  const [scrolled,setScrolled] = useState(false)
  const scrollFunc = (scroll) => {
    if(scroll>0) setScrolled(true)
    else setScrolled(false)
  }

  return (
    <div className="App">
      <link href="https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet"></link>
        <div className={scrolled ? "gridWrapper animated" : "gridWrapper"}>
            <Router>
              <Topbar scrolled={scrolled ? true : false}/>
              <Route exact path="/">
                <Home scrolled={scrolled ? true : false} scrollFunc={scrollFunc}/>
              </Route>
              <Route path="/offers/:slug">
                <CareerOffer scrolled={scrolled ? true : false} scrollFunc={scrollFunc}/>
              </Route>
          </Router>
        </div>
    </div>
  );
}

export default App;