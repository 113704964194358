import React, { useState,setState } from 'react';
import styles from './Contact.module.scss';
import { useQuery } from "graphql-hooks";
import Button from './../Button/Button.js'
import city from './../../assets/images/city.svg';
import mail from './../../assets/images/mail.svg';
import phone from './../../assets/images/phone.svg';
import emailjs from 'emailjs-com'

const CONTACTQUERY = `query {
    allConfigs {
        address
        addressLink
        addressPostcodeCity
        mailAddress
        phoneNumber
      }
    }`;
const Contact = () => {
    const[inputValues,setInputValues] = useState({name:'',email:'',msg:''});
    const[errors,setErrors] = useState({error:false,errorMessage:[]});
    const[sentStatus,setSendStatus] = useState(false);

    let templateParams = {
        name : inputValues.name,
        email : inputValues.email,
        message : inputValues.msg,
    }

    function sendMail(){
        setErrors({error:false,errorMessage:[]});
        emailjs.send('service_0j05k3o','template_zx8uufm',templateParams,'user_9S5C8SW8DHXdbFL5c5dkE')
        .then((response) =>{
            setSendStatus(<div className={styles.message__cleared}>Wiadomość wysłana pomyślnie</div>);
        },(err) =>{
            setSendStatus(<div className={styles.message__failed}>Błąd w wysylaniu wiadomości</div>)
        });
    }
    function handleSubmit(e){
        e.preventDefault();
        let msg = [];
        let error = false;
        if(inputValues.name.length === 0){
            error = true;
            msg = [...msg,'Nie wpisano imienia'];
        }
        if(!inputValues.email.match(/^[\w\d.!#$%&'+/=?^`{|}~-]+@[\w\d]+(?:.[\w\d]+)$/)){
            error = true;
            msg = [...msg,"Nieprawidłowy adres e-mail"];
        }
        if(inputValues.msg.length === 0){
            error = true;
            msg = [...msg,'Nie wypełniono pola wiadomości']
        }
        error ? setErrors ({error:error,message : msg}) : sendMail();
    }
    const handleChange = (e) =>{
       setInputValues({...inputValues,[e.target.name]:e.target.value})
    }

    const {loading, error, data} = useQuery(CONTACTQUERY);
    if (loading) return "Loading...";
    if (error) return console.log(error);
    return (
    <div className={styles.container}>
        <form className={styles.form}>
            <label className={styles.form__label}>Imię i nazwisko</label>
            <input type='text' className={styles.form__input} onChange={handleChange} value = {inputValues.name} name='name'></input>
            <label className={styles.form__label}>Email</label>
            <input type='text' className={styles.form__input} onChange={handleChange} value = {inputValues.email} name='email'></input>
            <label className={styles.form__label}>Wiadomość</label>
            <textarea type='text' className={styles.form__textarea} onChange={handleChange} value = {inputValues.msg}name='msg'></textarea>
            {errors.error ? errors.message.map((error)=> <div className={styles.message__failed}>{error}</div>): null}
            {sentStatus ? sentStatus : null}
            <Button onClick={handleSubmit}>Wyślij</Button>
        </form>
        <div className={styles.contactDetails}>
        <img src={phone} className={styles.contactDetails__singleImage}/>
        <a href={`tel:${data.allConfigs[0].phoneNumber}`} className={styles.contactDetails__single}><span>{data.allConfigs[0].phoneNumber}</span></a>
        <img src={mail} className={styles.contactDetails__singleImage}/>
        <a href={`mailto:${data.allConfigs[0].mailAddress}`} className={styles.contactDetails__single}><span>{data.allConfigs[0].mailAddress}</span></a>
        <img src={city} className={styles.contactDetails__singleImage}/>
        <a href={data.allConfigs[0].addressLink} className={styles.contactDetails__single}><span>{data.allConfigs[0].address}<br/>{data.allConfigs[0].addressPostcodeCity}</span></a>
        </div>
    </div>
)
}

export default Contact;