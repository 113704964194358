import React, {useState} from 'react';
import styles from './Topbar.module.scss';
import logo from './../../assets/images/logo.svg';
import {HashLink} from 'react-router-hash-link'

const Topbar = (props) => {

    const [hamburgerActive, setHamburgerActive] = useState(false)

    return(
        <>
            <div className={[styles.container, props.scrolled ? styles.animated : null].join(" ")}>
                <div className={styles.Topbar}>
                    <HashLink to="/#start" className={`${styles.Topbar__image} ${hamburgerActive ? styles.animated : ''}`} smooth={true}>
                        <img src={logo}/>
                    </HashLink>
                    <div className={`${styles.Topbar__separator} ${hamburgerActive ? styles.animated : ''}`}></div>
                    <HashLink to="/#start" className={styles.Topbar__link} smooth={true}>START</HashLink>
                    <HashLink to="/#services" className={styles.Topbar__link} smooth={true}>USŁUGI</HashLink>
                    <HashLink to="/#team" className={styles.Topbar__link} smooth={true}>NASZ ZESPÓŁ</HashLink>
                    <HashLink to="/#career" className={styles.Topbar__link} smooth={true}>KARIERA</HashLink>
                    <HashLink to="/#contact" className={styles.Topbar__link} smooth={true}>KONTAKT</HashLink>
                    <div onClick={() => setHamburgerActive(!hamburgerActive)} className={`${styles.hamburger} ${hamburgerActive ? styles.animated : ''}`}>
                        <div className={styles.hamburger__line}/>
                        <div className={styles.hamburger__line}/>
                        <div className={styles.hamburger__line}/>

                    </div>
                    <div className={`${styles.hamburgerMenu} ${hamburgerActive ? styles.animated : ''}`}>
                        <HashLink to="/#start" onClick={()=>setHamburgerActive(false)} className={styles.hamburgerMenu__link} smooth={true}>START</HashLink>
                        <HashLink to="/#services" onClick={()=>setHamburgerActive(false)} className={styles.hamburgerMenu__link} smooth={true}>USŁUGI</HashLink>
                        <HashLink to="/#team" onClick={()=>setHamburgerActive(false)} className={styles.hamburgerMenu__link} smooth={true}>NASZ ZESPÓŁ</HashLink>
                        <HashLink to="/#career" onClick={()=>setHamburgerActive(false)} className={styles.hamburgerMenu__link} smooth={true}>KARIERA</HashLink>
                        <HashLink to="/#contact" onClick={()=>setHamburgerActive(false)} className={styles.hamburgerMenu__link} smooth={true}>KONTAKT</HashLink>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Topbar;