import React from 'react';
import { useQuery } from "graphql-hooks";
import styles from './Team.module.scss';

const TEAMQUERY = `query {
    allTeams {
      name, slug, description, corporatePosition
    }
  }`;

const Team = () => {
  const {loading, error, data} = useQuery(TEAMQUERY);
  if (loading) return "Loading...";
  if (error) return console.log(error);
  return (
    <div div className={styles.container} id="team">
        {
        data.allTeams.map((node) => {
          return (
          <div key={node.slug} className={styles.team}>
            <div className={styles.team__image}></div>
            <div className={styles.team__name}>{node.name}</div>
            <div className={styles.team__divider}></div>
            <div className={styles.team__position}>{node.corporatePosition}</div>
            <div className={styles.team__description}>{node.description}</div>
          </div>
          )
        })}
    </div>
  )
}
export default Team;