import React,{useRef,useState} from 'react'
import styles from './CareerOfferForm.module.scss'
import Button from './../Button/Button.js'
import ReactFilestack from 'filestack-react';
import emailjs from 'emailjs-com'

const YOUR_API_KEY = "AeiJrobeVTuimlBNA0NJgz";

const CareerOfferForm = () => {

    const [inputValues,setInputValues] = useState({name:'',email:'',portfolio:'',consent:false});

    const [message,setMessage] = useState(<></>)
    const [fileLink,setFileLink] = useState(false)
    const [errors,setErrors] = useState({error : false,message : []})

    const [sendStatus,setSendStatus] = useState(false);

    function onFileUpload(file) {
        if (file.filesUploaded[0].size >= 3500000 && file.filesUploaded[0].originalFile.type ==='application/pdf') {
            setMessage(<div className={styles.message__error}>Zbyt duży plik</div>)
        }
        else if (file.filesUploaded[0].originalFile.type !=='application/pdf') {
            setMessage(<div className={styles.message__error}>To nie jest plik pdf</div>)
        }
        else {
        setMessage(<div className={styles.message__ok}>Poprawnie załadowano plik {file.filesUploaded[0].filename}<div className={styles.buttonfile} onClick={handleFileChange}>Wybierz inny plik</div></div>)
            setFileLink(file.filesUploaded[0].url);
        }
    }
    
    let templateParams = {
        name : inputValues.name,
        email : inputValues.email,
        portfolio : inputValues.portfolio,
        cv : fileLink,
    }

    function sendMail() {
        setErrors({error : false,message : []});
        emailjs.send('service_0j05k3o','template_zuu1k2g',templateParams,'user_9S5C8SW8DHXdbFL5c5dkE')
        .then((response) => {
            setSendStatus(<div className={styles.message__ok}>Zgłoszenie wysłane poprawnie</div>);
        }, (err) => {
            setSendStatus(<div className={styles.message__error}>Błąd w wysyłaniu zgłoszenia</div>);
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        console.log(inputValues);
        let msg = [];
        let error = false;
        if(inputValues.name.length === 0){
            error = true;
            msg = [...msg,'Nie wpisano imienia'];
        }
        if(!inputValues.email.match(/^[\w\d.!#$%&'+/=?^`{|}~-]+@[\w\d]+(?:.[\w\d]+)$/)){
            error = true;
            msg = [...msg,"Nieprawidłowy adres e-mail"];
        }
        if(!inputValues.portfolio.match(/http(s)?:\.?(www.)?[\W\d@:%.+~#=]{2,256}.[\w]{2,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/) && !(inputValues.portfolio.length === 0)){
            error = true;
            msg = [...msg,"Nieprawidłowy adres portfolio"];
        }
        if(inputValues.consent === false){
            error = true;
            msg = [...msg,"Nie wyrażono zgody na przetwarzanie danych"];
        }
        if(!fileLink){
            error = true;
            msg = [...msg,"Nie przesłano CV"];
        }
        error ? setErrors ({error:error,message : msg}) : sendMail();
        
    }
    const handleChange = (e) => {
        e.target.type === 'checkbox' ? setInputValues({...inputValues, [e.target.name]:e.target.checked}) : setInputValues({...inputValues, [e.target.name]:e.target.value});
    }

    const handleFileChange = (e) => {
        e.preventDefault();
        setFileLink(false);
        setMessage(<></>);
    }

    const [filename,setFilename] = useState('Tylko pliki .pdf. Maksymalny rozmiar pliku 3 MB.');
    const onFilesChange = (e) => {
        setFilename(e[0].name);
    }
    return (
        <form className={styles.container}>
            <p className={styles.inputlabel}>Imię i nazwisko</p>
            <input type='text' className={styles.inputfield} onChange={handleChange} value = {inputValues.name} name='name'></input>
            <p className={styles.inputlabel}>Email</p>
            <input type='text' className={styles.inputfield} onChange={handleChange} value = {inputValues.email} name='email'></input>
            <p className={styles.inputlabel}>Portfolio (opcjonalnie)</p>
            <input type='text' className={styles.inputfield} onChange={handleChange} value = {inputValues.portfolio} name='portfolio'></input>
            <p className={styles['inputlabel--disabled']}>Link do portfolio</p>
            <p className={styles.inputlabel}>CV</p>
            <p className={styles['inputlabel--disabled']}>Plik w formacie PDF, maksymalnie 3.5MB</p>
            <div id='dnd' className={styles.dragAndDrop} onChange={handleChange} value = {inputValues.file} style={{ ...!fileLink ? {display: 'block'} : {display: 'none'}}}></div>
            {message}
            <div className={styles.filecontainer}>
                <input type='checkbox' id='idconsent' name='consent' checked={inputValues.consent} onChange={handleChange}/>
                <label className={styles.consenttext} htmlFor='idconsent'>No i ja się pytam człowieku dumny ty jesteś z siebie zdajesz sobie sprawę z tego co robisz?masz ty wogóle rozum i godność człowieka?</label>
            </div>
            <ReactFilestack
            apikey={YOUR_API_KEY}
            onSuccess={(res) => onFileUpload(res)}
            componentDisplayMode={{
                type: 'immediate'
            }}
            actionOptions={{
                displayMode: "dropPane",
                container: "dnd"
            }}/>

            <Button onClick={handleSubmit}>Wyślij zgłoszenie</Button>
            {errors.error ? console.log(errors) : null}
            {errors.error ? errors.message.map((error) => <div className={styles.message__error}>{error}</div>) : null }
            {sendStatus ? sendStatus : null}
        </form>
    )
}

export default CareerOfferForm;