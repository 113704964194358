import React from 'react';
import { useQuery } from "graphql-hooks";
import styles from './SectionWrapper.module.scss';

const TEAMQUERY = `query {
    allTeams {
      name, slug, description, corporatePosition
    }
  }`;

const Team = (props) => {
  const {loading, error} = useQuery(TEAMQUERY);
  if (loading) return "Loading...";
  if (error) return console.log(error);
  return (
    <div className={styles.wrapper} id = {props.id}>
      {props.title ? <p className={styles.wrapper__title}><span style={{color: 'red'}}><b>|</b>&nbsp;</span>{props.title}</p> : null}
      {props.subtitle ? <p className={styles.wrapper__subtitle}>{props.subtitle}</p> : null }
      {props.description ? <p className={styles.wrapper__description}>{props.description}</p> : null }
        {props.children}
    </div>
  )
}
export default Team;