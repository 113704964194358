import React from 'react';
import { useQuery } from "graphql-hooks";
import styles from './Career.module.scss';
import city from './../../assets/images/city.svg';
import experience from './../../assets/images/experience.svg';
import contractType from './../../assets/images/contractType.svg';
import workingTime from './../../assets/images/workingTime.svg';
import { Link } from "react-router-dom";

const TEAMQUERY = `query {
  allCareers {
    title
    experience
    contractType
    city
    workingTime
    slug
  }
  }`;

const Career = () => {
  const {loading, error, data} = useQuery(TEAMQUERY);
  if (loading) return "Loading...";
  if (error) return console.log(error);
  return (
      <div className={styles.container}>
        {
        data.allCareers.map((node) => {
          return (
          <Link to={`/offers/${node.slug}`} key={node.slug} className={styles.career}>
            <div className={styles.career__title}>{node.title}</div>
            <div className={styles.career__divider}></div>
            <div className={styles.career__grid}>
              {node.city ? <><img src={city} alt='' className={styles.career__gridImage}/><div className={styles.career__gridText}>{node.city}</div></> : null}
              {node.city ? <><img src={experience} alt='' className={styles.career__gridImage}/><div className={styles.career__gridText}>{node.experience}</div></> : null}
              {node.city ? <><img src={contractType} alt='' className={styles.career__gridImage}/><div className={styles.career__gridText}>{node.contractType}</div></> : null}
              {node.city ? <><img src={workingTime} alt='' className={styles.career__gridImage}/><div className={styles.career__gridText}>{node.workingTime}</div></> : null}
            </div>

          </Link>
          )
        })}
      </div>
  )
}
export default Career;