import React,{useState, useRef} from 'react';
import styles from './CareerOffer.module.scss';
import {useParams} from 'react-router-dom';
import { useQuery } from "graphql-hooks";
import Footer from './../Footer/Footer.js'
import city from './../../assets/images/city.svg';
import experience from './../../assets/images/experience.svg';
import contractType from './../../assets/images/contractType.svg';
import workingTime from './../../assets/images/workingTime.svg';
import CareerOfferForm from './../CareerOfferForm/CareerOfferForm.js'
import Button from '../Button/Button';

const CareerOffer = (props) => {
    const myRef = useRef(null)
    const scrollFunc = () => {
        props.scrollFunc(myRef.current.scrollTop);
    }
    let { slug } = useParams();
    const CAREEROFFER = `query {
        career(filter: {slug: {eq: "${slug}"}}) {
            city
            contractType
            experience
            title
            workingTime
            description
            requirement
          }
        }`;
    const showFormFunc = () => {
        setShowform(true);
        setButtonVariant('Inactive');
    }
    const [buttonVariant,setButtonVariant] = useState('');
    const[showform,setShowform] = useState(false);
    const {loading, error, data} = useQuery(CAREEROFFER);
    if (loading) return "Loading...";
    if (error) return console.log(error);

    return (
        <div className={styles.wrapper} ref={myRef} onScroll={scrollFunc}>
            <div className={styles.container}>
                <p className={styles.title}>{data.career.title}</p>
                <div className={styles.grid}>
                    <img src={city} className={styles.grid__image}/>
                    <p className={styles.grid__text}>{data.career.city}</p>
                    <img src={experience} className={styles.grid__image}/>
                    <p className={styles.grid__text}>{data.career.experience}</p>
                    <img src={contractType} className={styles.grid__image}/>
                    <p className={styles.grid__text}>{data.career.contractType}</p>
                    <img src={workingTime} className={styles.grid__image}/>
                    <p className={styles.grid__text}>{data.career.workingTime}</p>
                </div>
                <p className={styles.subtitle}>Opis</p>
                <div className={styles.description}><pre>{data.career.description}</pre></div>
                <p className={styles.subtitle}>Wymagania</p>
                <div className={styles.description}><pre>{data.career.requirement}</pre></div>
                <Button onClick={showFormFunc} variant={buttonVariant}>Aplikuj</Button>
                {showform ? <CareerOfferForm/>:null}
            </div>
            <Footer/>
        </div>
    )
}

export default CareerOffer;