import React from 'react';
import styles from './Footer.module.scss';
import logo from './../../assets/images/logo.svg'
import { useQuery } from "graphql-hooks";
import city from './../../assets/images/city.svg';
import mail from './../../assets/images/mail.svg';
import phone from './../../assets/images/phone.svg';
import back from './../../assets/images/back.svg';
import {HashLink} from 'react-router-hash-link'

const CONTACTQUERY = `query {
    allConfigs {
        address
        addressLink
        addressPostcodeCity
        mailAddress
        phoneNumber
      }
    }`;

const Footer = () => {
    const {loading, error, data} = useQuery(CONTACTQUERY);
    if (loading) return "Loading...";
    if (error) return console.log(error);
    return (
        <div className={styles.container}>
            <div className={styles.Footer}>
                <img src={logo} className={styles.Footer__logo}/>
                <div className={styles.Footer__separator}></div>
                <div className={styles.Footer__contact}>
                    <div className={styles.Footer__contactTitle}><span style={{color: 'red'}}><b>|&nbsp;</b></span>KONTAKT</div>
                    <img src={phone} className={styles.Footer__contactImage}/>
                    <a href={`tel:${data.allConfigs[0].phoneNumber}`} className={styles.Footer__contactDetail}><span>{data.allConfigs[0].phoneNumber}</span></a>
                    <img src={mail} className={styles.Footer__contactImage}/>
                    <a href={`mailto:${data.allConfigs[0].mailAddress}`} className={styles.Footer__contactDetail}><span>{data.allConfigs[0].mailAddress}</span></a>
                    <img src={city} className={styles.Footer__contactImage}/>
                    <a href={data.allConfigs[0].addressLink} className={styles.Footer__contactDetail}><span>{data.allConfigs[0].address}<br/>{data.allConfigs[0].addressPostcodeCity}</span></a>
                </div>
                <div className={styles.Footer__separator}></div>
                <div className={styles.Footer__siteMap}>
                    <div className={styles.Footer__siteMapTitle}><span style={{color: 'red'}}><b>|&nbsp;</b></span>MAPA STRONY</div>
                    <HashLink to="/#start" className={styles.Footer__siteMapDetail}>Start</HashLink>
                    <HashLink to="/#services" className={styles.Footer__siteMapDetail}>Usługi</HashLink>
                    <HashLink to="/#team" className={styles.Footer__siteMapDetail}>Nasz zespół</HashLink>
                    <HashLink to="/#career" className={styles.Footer__siteMapDetail}>Kariera</HashLink>
                    <HashLink to="/#contact" className={styles.Footer__siteMapDetail}>kontakt</HashLink>
                </div>
                <div className={styles.Footer__separator}></div>
                <HashLink to="/#start" className={styles.Footer__backButton}>
                    <img src={back} className={styles.Footer__backButtonImage}/>
                    <div className={styles.Footer__backButtonText}>Do góry</div>
                </HashLink>
            </div>
        </div>
    )
}

export default Footer;