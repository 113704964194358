import React from 'react';
import Button from './../../components/Button/Button.js'
import { useQuery } from "graphql-hooks";
import styles from './Service.module.scss';
import {useMediaQuery} from 'react-responsive'
const TEAMQUERY = `query {
  allServices {
    description
    name
  }
  }`;

const Service = () => {
  const isMobile = useMediaQuery({query : '(min-width: 1023px'});

  const {loading, error, data} = useQuery(TEAMQUERY);
  if (loading) return "Loading...";
  if (error) return console.log(error);

  return (
      <div className={styles.container} id="service">
        <div className={styles.service__grid}>
          {
          data.allServices.map((node) => {
            return (
            <div key={node.slug} className={styles.service}>
              <div className={styles.service__name}>{node.name}</div>
              <div className={styles.service__divider}></div>
              <div className={styles.service__description}>{node.description}</div>
            </div>
            )
          })}
        </div>
        {isMobile && <Button className={styles.dupa} onClick={() => window.location.href="#contact"} onBu>Zapytaj o wycenę</Button>}
      </div>
  )
}
export default Service;