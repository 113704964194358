import React, { useEffect, useState, useRef } from 'react';
import styles from './Home.module.scss';
import katana from './../../assets/images/katana.svg';
import arrow from './../../assets/images/back.svg';
import Team from './../Team/Team.js';
import Career from './../Career/Career.js';
import Button from './../Button/Button.js';
import Service from './../Service/Service.js';
import SectionWrapper from './../SectionWrapper/SectionWrapper.js';
import Contact from './../Contact/Contact.js';
import Footer from './../Footer/Footer.js';
import {useMediaQuery} from 'react-responsive'

const Home = (props) => {
    const isMobile = useMediaQuery({query : '(min-width:1023px)'});
    const myRef = useRef(null)
    const scrollFunc = () => {
        props.scrollFunc(myRef.current.scrollTop);
    }

    return (
        <div className={styles.wrapper} onScroll={scrollFunc} ref={myRef}>
            <div className={styles.secondWrapper}>
                <section id="start">
                    <div className={styles.container}>
                        <div className={styles.separator}/>
                        <div className={styles.home}>
                            <p className={styles.home__text}>
                                Dostarczamy <span style={{color: 'red'}}>specjalistów</span><br/>
                                władających <span style={{color: 'red'}}>kodem</span><br/>
                                <Button className={styles.home__button} onClick={() => window.location.href='#career'} style={{marginTop: 55}}>Sprawdź ofertę</Button>
                            </p>
                            <div className={styles.home__separator}></div>
                            <img src={katana} className={styles.home__logo}/>
                        </div>
                        <div className={styles.separator}/>
                        <img src={arrow} onClick={()=>window.location.href='#career'} className={styles.more}/>
                    </div>
                </section>
                <SectionWrapper title={'NASZE USŁUGI'} id="services" subtitle={'Co możemy dla Ciebie zrobić?'} description={'Tekst - czemu Ronin i co to praktycznie oznacza. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor. Sed do eiusmod tempor incididunt ut labore et dolor.'}>
                        <Service/>
                </SectionWrapper>
                <SectionWrapper title={'NASZ ZESPÓŁ'} id="team"subtitle={'Kim jesteśmy?'}>
                    <Team/>
                </SectionWrapper>
                <SectionWrapper title={'KARIERA'} id="career" subtitle={'Dołącz do nas!'}>
                    <Career/>
                </SectionWrapper>
                <SectionWrapper title={'KONTAKT'} id="contact" subtitle={'Skontaktuj się z nami'}>
                    <Contact/>
                </SectionWrapper>
                <Footer/>
            </div>
        </div>
    )
}

export default Home;